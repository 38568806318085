.heading {
    height: 51px;
    width: 100%;
    font-family: Poppins;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    text-align: left;
    color: #192f60;
    margin-bottom: 10px;
    margin-left: 15px;
}

.filtercontainer {
    height: 100%;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    margin-left: 10px;
}

.filter-options {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding-left: 30px;
    margin-bottom: 10px;
    padding-bottom: 3%;
}

.filter-heading {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #192f60;
    padding-top: 7px;
    display: inline-block;
}

.Select-Location {
    height: 20px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #192f60;
    margin-top: 30px;
}

.Rectangle-2236 {
    width: 200px;
    height: 25px;
    border-radius: 5px;
    border: solid 1px #8c96ab;
    margin-top: 10px;
}

.Cuisine {
    height: 20px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #192f60;
    margin-top: 12px;
}

.checkbox-items {
    height: 20px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #8c96ab;
}

.img {
    width: 100px;
    height: 100px;
    border-radius: 35px;
    margin-left: 25%;
}

.rest-name {
    height: 43px;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    text-align: left;
    color: #192f60;
}

.toggle-span {
    float: right;
    padding: 5px 0px 5px 0px;
    display: none;
}

.rest-location {
    height: 23px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left;
    color: #192f60;
}

.rest-address {
    height: 23px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left;
    color: #636f88;
}

.Bakery-700 {
    height: 40px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #192f60;
}

.padding-left {
    padding-left: 60px;
}

.custom-button {
    color: black;
    font-weight: bold;
    text-decoration: none;
    box-shadow: 0 0 0 0 white;
}

.pagination {
    display: inline-block;
    margin-left: 275px;
    margin-top: -3px;
}

.pagination a {
    color: gray;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    border: solid 1px gray;
    border-radius: 15px;
    margin: 2px;
}

.Item {
    width: 100%;
    height: 220px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    margin-bottom: 15px;
}

.big {
    display: inline-block;
    width: 68%;
}

.vertical {
    vertical-align: top;
}

.small {
    display: inline-block;
    width: 28%;
}

.big-item {
    display: inline-block;
    width: 68%;
}

.small-item {
    display: inline-block;
    width: 28%;
}

.margin-left {
    margin-left: 7%
}

.noData{
    font-size: 40px;
}

.cursurPointer{
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .Item {
        width: 100%;
        height: 200px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        margin-bottom: 15px;
    }
    .filter-options {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        padding-left: 30px;
        margin-bottom: 10px;
        min-width: 450px;
    }
    .big {
        display: block;
        width: 68%;
    }
    .small {
        display: block;
        width: 28%;
    }
    .big-item {
        display: inline-block;
        width: 68%;
    }
    .small-item {
        display: inline-block;
        width: 28%;
    }
    .padding-left {
        padding-left: 3px;
    }
    .filter-options {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        padding-left: 30px;
        margin-bottom: 10px;
        width: 100%;
        margin-left: 15px;
        min-height: 25px;
    }
    .rest-name {
        height: 43px;
        font-family: Poppins;
        font-size: 30px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.53;
        letter-spacing: normal;
        text-align: left;
        color: #192f60;
        margin-left: 20px;
    }
    .rest-location {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.88;
        letter-spacing: normal;
        text-align: left;
        color: #192f60;
        padding-left: 27px;
    }
    .rest-address {
        height: 23px;
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.88;
        letter-spacing: normal;
        text-align: left;
        color: #636f88;
        margin-left: 27px;
    }
    .toggle-span {
        float: right;
        padding: 15px 0px 5px 0px;
        display: initial;
    }
    .pagination {
        display: inline-block;
        margin-top: 25px;
        width: 380px;
        margin-left: 10%;
    }
}

