.tabs {
    position: relative;
    min-height: 200px;
    clear: both;
    margin: 50px 0 25px;
    background: white;
}

.tab {
    float: left;
}

.add-button {
    position: absolute;
    top: 84%;
    left: 32%;
}

.add-number {
    position: absolute;
    top: 83%;
    left: 25%;
}

.qty {
    background-color: White !important;
    width: 18px;
    display: inline-block;
    padding: 5px;
}

.tab label {
    background: white;
    padding: 2px;
    border: 1px solid white;
    margin-left: 15px;
    position: relative;
    left: 8px;
    top: -29px;
}

.tab [type=radio] {
    display: none;
}

.content {
    position: absolute;
    top: -1px;
    left: 0;
    background: white;
    right: 0;
    bottom: 0;
    padding: 20px;
    border-top: 1px solid gray;
    opacity: 0;
}

[type=radio]:checked~label {
    background: white;
    border-bottom: 1px solid white;
    z-index: 0;
    border-bottom: solid 4px red;
}

[type=radio]:checked~label~.content {
    z-index: 0;
    opacity: 1;
}

.button {
    position: absolute;
    left: 80%;
    top: 40%;
    width: 200px;
    height: 35px;
    opacity: 0.6;
    border-radius: 3px;
    background-color: #ffffff;
}

.heading {
    width: 298px;
    height: 43px;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    text-align: left;
    color: #192f60;
}

.about {
    width: 181px;
    height: 31px;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #192f60;
}

.head {
    height: 25px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #192f60;
}

.btn{
    background-color: #dc3545 !important;
    color: #FFFFFF !important;
}

.btn-success{
    background-color: green !important;
}

.btn:hover{
    color:  #FFFFFF !important;
}
.value {
    height: 30px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left;
    color: #192f60;
}

.btn-order {
    float: right;
    width: 170px;
    height: 35px;
    border-radius: 6px;
    background-color: #ce0505;
    font-family: Poppins;
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

@media only screen and (max-width: 600px) {
    .button {
        position: absolute;
        left: 55%;
        top: 40%;
        width: 200px;
        height: 35px;
        opacity: 0.6;
        border-radius: 3px;
        background-color: #ffffff;
    }
}